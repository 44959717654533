import Vue from "vue"
import VueGtm from '@gtm-support/vue2-gtm';

import './plugins/axios'
import App from "./App.vue"
// import App from "./components/FormElements/FormResult2.vue"
import store from "./store"
import ptBR from 'vee-validate/dist/locale/pt_BR';
import CpfValidator from './components/validators/cpf.validator'

import VeeValidate, { Validator } from "vee-validate"


import VueCookies from 'vue-cookies'
Vue.use(VueCookies, {
  sameSite: 'lax'
})
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "GTM-NBD5XXW" }
});
Vue.use(VueGtm, {
  id: 'GTM-NBD5XXW',
  defer: true,
  enabled: true,
  loadScript: true,
  trackOnNextTick: false
});
import VueYouTubeEmbed from 'vue-youtube-embed'
import SvgSprite from 'vue-svg-sprite';
Vue.use(VueYouTubeEmbed)
import VueMask from 'v-mask'
Vue.use(VueMask);
// import { VueMaskDirective } from 'v-mask'
// Vue.directive('mask', VueMaskDirective);

// import {VMoney} from 'v-money'
// Vue.directive('money', VMoney);

// Vue.use(svgSpritePlugin, {
//   url: '/assets/img/sprite.svg',
//   class: 'svg-icons',
// })
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

Vue.use(SvgSprite, {
  url: '/img/sprite.svg',
  class: 'icon',
});

// import "./directives"
import vuetify from './plugins/vuetify';
import router from './router'
import "./assets/styles/main.scss";
Validator.extend('cpf', CpfValidator)

Vue.use(VeeValidate)
Validator.localize('pt_BR', ptBR);
Vue.config.productionTip = true
console.log("%cCuidado!!! Tudo que fizer aqui será monitorado.", "background: blue; color: red; font-size: x-large");

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount("#app")
