<template>
  <type-based-transition :transition-type="'fadeUpDown'">
    <div
      v-if="showResult"
      id="complete"
      class="form-complete"
    >
      <v-container>
        <v-row justify="center">
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-row>
              <v-col class="text-center">
                <h6 class="text-sm-title text-md-h6 color-best">
                  Olá {{ formData.name }}, veja o resultado da simulação
                </h6>
              </v-col>
            </v-row>
            <v-divider />
            <v-skeleton-loader
              :loading="loading"
              width="500"
              height="100%"
              type="article, article, article, button"
            >
              <v-row class="justify-center">
                <v-col
                  cols="6"
                  class="text-center"
                >
                  <h4 class="text-subtitle-2 text--disabled">
                    Empréstimo simulado
                  </h4>
                  <h3
                    class="title"
                    style="white-space: normal;"
                  >
                    {{ dataResult.valorSolicitado.toLocaleString("pt-br", { style: "currency", currency: "BRL" }) }}
                  </h3>
                </v-col>
              </v-row>
              <v-divider />
              <v-row class="1pb-0">
                <v-col no-gutters>
                  <span class="title">Você está na melhor Fintech de Crédito do Brasil</span>
                  <h4 class="text-subtitle-1">
                    Estamos encontrando a melhor opção de crédito para você. Envie um whatsapp com o código abaixo para saber mais sobre o seu empréstimo ou aguarde nosso contato.
                  </h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="text-center"
                >
                  <h4 class="text-subtitle-2 text--disabled">
                    Código da simulação
                  </h4>
                  <h3 class="title">
                    {{ dataResult.codigoSimulacao || "" }}
                  </h3>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                class="justify-center"
              >
                <v-col cols="auto">
                  <v-btn
                    rounded
                    x-large
                    color="#08ca32"
                    class="pa-5 ma-2"
                    :href="url || 'https://wa.me/554730483888?text=Olá,%20eu%20gostaria%20de%20saber%20mais%20sobre%20o%20meu%20empréstimo%20na%20Itscred'"
                  >
                    <b
                      style="color: #fff"
                      class="text-h6"
                    >Enviar código</b>
                  </v-btn>
                </v-col>
              </v-row>
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </type-based-transition>
</template>
<script>
/* eslint-disable no-console */
import TypeBasedTransition from "../Transitions/TypeBasedTransition.vue"
import { mapState } from "vuex"
export default {
  name: "FormResult",
  components: {
    TypeBasedTransition,
  },
  data() {
    return {
      showHint: false,
    }
  },
  computed: {
    ...mapState({
      showResult: (state) => state.lead.result,
      loading: (state) => state.lead.loading,
      dataResult: (state) => state.lead.dataResult,
      formData: (state) => state.lead.formData,
      url: (state) => state.lead.url,
      valid: (state) => state.lead.valid,
      isFinan: (state) => (state.lead.formData.objetivo == "1" ? true : false),
    }),
  },
  methods: {
    toogleHint() {
      this.showHint = this.showHint ? false : true
    },
  },
}
</script>
<style scoped>
.color-best {
}
.row {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 18px;
}

.row > [class*="col-"] {
  display: flex;
  flex-direction: column;
}
.quadro {
  background-color: #cccccc;
  border-radius: 10px;
  padding-top: 15px;
  justify-content: center;
}
.quadro-2 {
  margin-top: 10px;
}
.input {
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  margin: 10px;
}
.space {
  height: 30px;
}
div.saudacao {
  margin-top: 10px;
  margin-bottom: -10px;
}
@media screen and (max-width: 600px) {
  .row {
    padding-bottom: 10px;
  }
  div.saudacao h1 {
    font-size: 22px;
  }
  div.saudacao h3 {
    font-size: 8px;
  }
  div.saudacao h1,
  h3 {
    margin: 0;
  }
  div.saudacao {
    margin-top: 10px;
    margin-bottom: -10px;
  }
  .quadro {
    background-color: #cccccc;
    border-radius: 10px;
    padding-bottom: 15px;
    padding-top: 15px;
    justify-content: center;
  }
  .input {
    margin: 4px -10px;
    padding: 5px;
  }
  .quadro-2 {
    margin-top: 10px;
    padding: 0;
  }
  .space {
    height: 10px;
  }
}
</style>
