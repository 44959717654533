import FormTemplate from "./FormTemplate.vue"
import InputBox from "./FormElements/Fields/InputBox.vue"
import TextArea from "./FormElements/Fields/TextArea.vue"
import RadioButton from "./FormElements/Fields/RadioButton.vue"
import CheckBox from "./FormElements/Fields/CheckBox.vue"
import GroupInputBox from "./FormElements/Fields/GroupInputBox.vue"
import SelectBox from "./FormElements/Fields/SelectBox.vue"
import ComboBox from "./FormElements/Fields/ComboBox.vue"
import SwitchBox from "./FormElements/Fields/SwitchBox.vue"


const COMPONENT_MAP = {
  formTemplate: FormTemplate,
  text: InputBox,
  email: InputBox,
  tel: InputBox,
  number: InputBox,
  select: SelectBox,
  combobox: ComboBox,
  textarea: TextArea,
  radio: RadioButton,
  check: CheckBox,
  groupInput: GroupInputBox,
  inputsa: GroupInputBox,
  switch: SwitchBox

}

export function getComponent(type) {
  return COMPONENT_MAP[type]
}
