<template>
  <div>
    <v-switch
      :value="value"
      :label="label"
      @change="$emit('input', $event)"
    />
    <!-- </div> -->
  </div>
</template>
<script>
export default {
  props: {
    value: {
      required: true,

    },
    label: {
      required: false
    }
  },

  created() {
    console.log("created in switch", this)
   this.$emit('input', this.value)
  },
}
</script>
<style>
label {
  display: block;
}
</style>
