<template>
  <data-driven-transition :is-next="isNext">
    <div
      v-if="active"
      :key="fieldId"
      class="field-group"
    >
      <slot />
    </div>
  </data-driven-transition>
</template>
<script>
import DataDrivenTransition from "../Transitions/DataDrivenTransition.vue"

export default {
  name: "FieldGroup",
  components: {
    DataDrivenTransition
  },
  inject: ["formState"],
  props: ["fieldId"],
  computed: {
    active() {
      return this.formState.activeField === this.fieldId
    },
    isNext() {
      return this.formState.isNext
    }
  }
}
</script>
