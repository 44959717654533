<template>
  <div v-if="percent < 101">
    <div class="bar" />
    <v-progress-circular
      class="progress-circle d-none d-md-flex"
      :rotate="360"
      :size="80"
      :width="10"
      :value="percent"
      color="#3e8fec"
    >
      {{ count + "%" }}
    </v-progress-circular>
  </div>
</template>
<script>
import { TimelineLite } from "gsap"
import anime from "animejs"
export default {
  name: "FormProgress",
  inject: ["formState"],
  data() {
    return {
      count: 0,
    }
  },
  computed: {
    percent() {
      return (this.formState.activeField / (this.formState.formLength - 1)) * 100
    },
  },
  watch: {
    percent(newVal) {
      this.setCount(newVal)
      let el = document.getElementsByClassName("bar")
      let tl = new TimelineLite()
      tl.to(el, 1, {
        width: newVal + "%",
      })
    },
  },
  methods: {
    setCount(val) {
      const obj = { n: this.count }
      anime({
        targets: obj,
        n: val,
        round: 1,
        duration: 500,
        easing: "linear",
        update: () => {
          this.count = obj.n
        },
      })
    },
  },
}
</script>
<style>
.progress-circle {
  position: fixed;
  top: 80px;
  left: 15px;
}
.v-progress-circular__info {
  /* margin-top: -20px; */
}
</style>