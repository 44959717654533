<template>
  <v-app>
    <v-app-bar app style="
        background: rgb(70, 50, 218);
        background: linear-gradient(130deg, rgba(70, 50, 218, 1) 0%, rgba(6, 217, 250, 1) 100%);
      ">
      <!-- 00B12B <v-img contain class="logo-a " width="10%" src="./assets/img/logo_white.png" /> -->
      <img src="./assets/1.png" class="ml-10" width="130" alt="Logotipo itscred">
    </v-app-bar>
    <v-container class="fill-height" fluid>
      <v-layout justify-center align-center>
        <form-config-provider :query_var="query_var" />
        <form-result />
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
import FormConfigProvider from "./components/FormConfigProvider.vue"
import FormResult from "./components/FormElements/FormResult.vue"
import FormProgress from "./components/FormElements/FormProgress.vue"

export default {
  name: "App",
  components: {
    FormConfigProvider,
    FormResult,
  },

  data() {
    return {
      query_var: {
        //objetivo: "",
      },
    }
  },

  mounted() {
    this.$gtag.pageview({
      page_path: '/',
      page_title: "Simulador",
    })
    this.$route.query.produto ? (this.query_var = this.$route.query) : null
    // this.$gtm.trackView("config", "GTM-NBD5XXW")
    this.$route.query.utm_source && this.$cookies.set("utm_source", this.$route.query.utm_source)
    this.$route.query.utm_medium && this.$cookies.set("utm_medium", this.$route.query.utm_medium)
    this.$route.query.utm_campaign && this.$cookies.set("utm_campaign", this.$route.query.utm_campaign)
    this.$route.query.utm_term && this.$cookies.set("utm_term", this.$route.query.utm_term)
    this.$route.query.utm_content && this.$cookies.set("utm_content", this.$route.query.utm_content)
    this.$route.query.ref_landing && this.$cookies.set("ref_landing", this.$route.query.ref_landing)
    this.$route.query.ref_referer && this.$cookies.set("ref_referer", this.$route.query.ref_referer)
    this.$route.query.utm_ref && this.$cookies.set("utm_ref", this.$route.query.utm_ref)
    this.$store.commit("lead/updateField", {
      key: "userAgent",
      value: navigator.userAgent,
    })

    this.axios('https://api.ipify.org?format=json').then(response => {
      // console.log(response.data.ip)
      this.$store.commit("lead/updateField", {
        key: "clientIp",
        value: response.data.ip,
      })
    })
    // this.$store.commit("lead/updateField", {
    //     field: "client_ip",
    //     value: data.query,
    //   })
  },
}
</script>
<style lang="scss">
#app {
  white-space: pre-line;
}

.form-button {
  white-space: normal !important;
}
</style>
<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css");
</style>
