<script>
export default {
  functional: true,
  props: {
    isNext: {
      default: true
    }
  },
  render(h, context) {
    var data = {
      props: {
        appear: true,
        appearActiveClass: "animated animate__fadeInUp",
        enterActiveClass: context.props.isNext ? "animate__animated animate__fadeInUp" : "animate__animated animate__fadeInDown",
        leaveActiveClass: context.props.isNext ? "animate__animated animate__fadeOutUp" : "animate__animated animate__fadeOutDown",
        name: "data-driven-transition",
        duration: 1000,
        mode: "out-in"
      }
    }
    return h("transition-group", data, context.children)
  }
}
</script>
