<template>
  <div>
    <form-progress />
    <!-- <template v-if="this.$route.query = 'true'">
      <v-text-field 
        type="tel"
        label="Type Tel"
        outlined
      />
      <v-text-field 
        type="text"
        label="Type text"
        outlined
      />
      <v-text-field 
        type="number"
        label="Type number"
        outlined
      />
      <v-text-field 
        type="number"
        label="Type number pattern"
        outlined
        pattern="[0-9]*"
      />
    </template> -->

    <form class="form-template" @submit.prevent="submit">
      <!-- Form Template -->
      <template v-for="(field, key) in formFields">
        <field-group v-if="field.type != 'inputs'" :key="`${key}-${field.name}`" :field-id="key">
          <div class="field-area my-5" :class="{ 'is-valid': isCurrentFieldValid }">
            <div class="pergunta mt-8">
              <field-label :for="`${_uid}-${field.name}`">
                {{ field.label }} <i v-if="field.hint" class="fa fa-info-circle" @click="toogleHint" />
                <span> {{ field.description }} </span> <span />
              </field-label>
              <field-error class="my-5">
                {{ errors.first(field.name) }}
              </field-error>
              <type-based-transition transition-type="fadeUpDown">
                <div v-if="showHint" class="card-hint">
                  <div>
                    <i class="fa fa-times-circle" @click="toogleHint" />
                    <p>{{ field.hint }}</p>
                    <span>{{ field.hint_description }}</span>

                    <!-- <youtube v-if="field.youtube" :player-vars="{ autoplay: 1 }" :video-id="field.youtube"></youtube> -->
                  </div>
                </div>
              </type-based-transition>
            </div>
            <div class="resposta my-10">
              <Component
                :is="field.component"
                :id="`${_uid}-${field.name}`"
                v-model="formData[field.name]"
                v-validate="field.fields ? false : field.validation"
                v-bind="{ ...field.options.attrs }"
                :name="`${field.name}`"
                :type="field.type"
                :disabled="field.disabled"
                :data-vv-as="field.label"
                :options="!!field.options.choices ? field.options.choices : field.fields"
                @input="updateField({ key: field.name, value: formData[field.name] })"
              />
            </div>
          </div>
          <form-nav @back="back" @next="next" @submit="submit" />
        </field-group>
        <field-group v-else :key="`${_uid}-${key}`" :field-id="key">
          <div class="field-area my-8" :class="{ 'is-valid': isCurrentFieldValid }">
            <div class="pergunta my-5">
              <field-label :for="`${_uid}-${key}`">
                {{ field.label }} <span> {{ field.description }} </span>
              </field-label>
            </div>
            <div class="inputs row">
              <template v-for="(f, i) in field.fields">
                <transition :key="i" name="slide-fade">
                  <v-col
                    v-if="getCondition(f) && (f.show || f.show === undefined)"
                    :key="i"
                    class="my-0 py-0 transition"
                    :cols="f.cols ? f.cols : false"
                    :sm="f.col_sm ? f.col_sm : false"
                    :md="f.col_md ? f.col_md : false"
                    :lg="f.col_lg ? f.col_lg : false"
                  >
                    <Component
                      :is="f.component"
                      :id="`${_uid}-${f.name}`"
                      :key="i"
                      v-model.lazy="formData[f.name]"
                      v-mask="f.mask"
                      v-validate="f.validation"
                      :is-money="f.isMoney ? true : false"
                      :label="f.label"
                      :description="f.description"
                      :hint="f.hint"
                      v-bind="{ ...f.options.attrs }"
                      :options="f.options"
                      :name="f.name"
                      :value="f.value"
                      :type="f.type"
                      :data-vv-as="f.label"
                      :loading="loading[f.name]"
                      :error-messages="errors.first(f.name)"
                      :class="{ input: true, 'is-danger': errors.has(f.name) ? true : false }"
                      @input="updateForm(f.name)"
                    />
                    <!-- 
                    <field-error>
                      <br />
                      {{ errors.first(f.name) }}</field-error> -->
                  </v-col>
                </transition>
              </template>
            </div>
          </div>
          <form-nav @back="back" @next="next" @submit="submit" />
        </field-group>
      </template>
    </form>
    <!-- <form-result /> -->
  </div>
</template>

<script>
import FieldGroup from "./FormElements/FieldGroup.vue"
import FieldError from "./FormElements/FieldError.vue"
import FieldLabel from "./FormElements/FieldLabel.vue"

import FormNav from "./FormElements/FormNav.vue"
import FormResult from "./FormElements/FormResult.vue"

import FormProgress from "./FormElements/FormProgress.vue"

import formMixin from "../mixins/formMixin"
import { mapMutations } from "vuex"

import TypeBasedTransition from "./Transitions/TypeBasedTransition.vue"
import axios from "axios"
export default {
  name: "FormTemplate",
  components: {
    "form-progress": FormProgress,
    FieldGroup,
    FieldError,
    FieldLabel,
    FormNav,
    FormResult,
    TypeBasedTransition,
  },
  mixins: [formMixin],
  props: ["formFields"],
  provide() {
    return {
      formState: this.formState,
    }
  },

  data() {
    return {
      showHint: false,
      mask: "",
      // REGEX_PLACA_GENERICA: /^([a-zA-Z]{3})([0-9]([a-zA-Z]|[0-9])[0-9]{2})$/,
      // REGEX_PLACA_ANTIGA: /^([a-zA-Z]{3})([0-9])([0-9])([0-9]{2})$/,
      // REGEX_PLACA_NOVA: /^([a-zA-Z]{3})([0-9])([a-zA-Z])([0-9]{2})$/,
      // REGEX_REPLACE: /[^0-9A-Za-z]/g,
    }
  },
  computed: {
    cols() {
      const { lg, sm } = this.$vuetify.breakpoint
      return lg ? [3, 9] : sm ? [9, 3] : [6, 6]
    },
  },
  methods: {
    updateForm(nameField) {
      if (this.formData[nameField] !== undefined) {
        this.updateField({ key: nameField, value: this.formData[nameField] })
        this.formData[nameField] = this.formData[nameField]
      }
    },
    focus(e) {},
    /*
    getItems(query) {
      let items = {
        choices: {
          items: [],
        }
      }
      if(query.function == 'getModels'){
      try {
        let brand = this.formData.brand
        let year = this.formData.manufacturingYear
        let models = axios.post('http://localhost:5001/itscred-emprestimos/us-central1/getModels', {
            brand: brand,
            year: year
        }).then(response => {
            if(response.status == 200 || response.status == 201) {
              let models = response.data.items
              console.log('models', models)
              for(let i = 0; i < models.length; i++) {
                items.choices.items.push(
                 models[i].model
                )
                // items.choices.items.push({
                //   text: models[i].model,
                //   value: models[i].model,
                //   header:  models[i].modelVersion 
                // })
              }
              // models.items.foreach(item => {
              //   items.choices.items.push({
              //                      text: item.model,
              //     value: item.model,
              //     header: item.modelVersion 
              //   })
              // })
             return 
        //   if (models.data.items.length == 0) return []
        // return models.data.items.map(model => {
        //   return {
        //     text: model.model,
        //     value: model.model,
        //     header: model.modelVersion
        //   }
        // })
        }
        })
        // if(models.status == 200 || models.status == 201) {
        //     return models.data
        // //   if (models.data.items.length == 0) return []
        // // return models.data.items.map(model => {
        // //   return {
        // //     text: model.model,
        // //     value: model.model,
        // //     header: model.modelVersion
        // //   }
        // // })
        // }
      //  return []
      } catch (error) {
        return error;
      }
        return items

          //items.choices.items = models
      }
    //  return items
    },
    getModel() {
        console.log('this')
      try {
        let brand = this.formData.brand
        let year = this.formData.manufacturingYear
        console.log('year', year)
        let models = axios.post('http://localhost:5001/itscred-emprestimos/us-central1/getModels', {
            brand: brand,
            year: year
        }).then(response => {
          console.log(response)
            if(response.status == 200 || response.status == 201) {
            return response.data
        //   if (models.data.items.length == 0) return []
        // return models.data.items.map(model => {
        //   return {
        //     text: model.model,
        //     value: model.model,
        //     header: model.modelVersion
        //   }
        // })
        }
        })
        // if(models.status == 200 || models.status == 201) {
        //     return models.data
        // //   if (models.data.items.length == 0) return []
        // // return models.data.items.map(model => {
        // //   return {
        // //     text: model.model,
        // //     value: model.model,
        // //     header: model.modelVersion
        // //   }
        // // })
        // }
      //  return []
      } catch (error) {
        return error;
      }
     // return []
    },
    */
    async upperCase(e) {
      const input = e.target
      input.value = input.value.toUpperCase()
      // if(input.name == "plate"){
      //    this.formData.plateValidation = false
      //     this.formData.manufacturingYear = null
      //     this.formData.brand = null
      //     if(input.value.length > 7){
      //     let plate = input.value.replace(this.REGEX_REPLACE, '')
      //     if(this.REGEX_PLACA_GENERICA.test(plate)){
      //        this.formData.plateValidation = true
      //       // this.loading.plate = 'primary'
      //       // try {
      //       //   let veiculo = await axios.post('http://localhost:5001/itscred-emprestimos/us-central1/getPlate', {
      //       //   plate: plate
      //       // })
      //       // if(veiculo.status == 200){
      //       //   this.loading.plate = false
      //       //   this.formData.manufacturingYear = veiculo.data.ano
      //       //   this.formData.modelYear = veiculo.data.anoModelo
      //       //   // this.formData.brand = veiculo.data.marca
      //       //   // this.formData.model = veiculo.data.modelo
      //       //   // this.formData.version = veiculo.data.versao
      //       // }
      //       // } catch (error) {
      //       //   this.loading.plate = false
      //       // }
      //     }
      //   }
      // }
    },
    ...mapMutations({
      updateField: "lead/updateField",
    }),
    toogleHint() {
      this.showHint = this.showHint ? false : true
    },

    // getCondition(field) {
    //   if (!field.hasOwnProperty("if")) {
    //     return true
    //   }
    //   switch (field.if.operator) {
    //     case "like":
    //       if (field.if.condition === this.formData[field.if.model]) {
    //         return true
    //       }
    //       break
    //     case "in":
    //       if (field.if.condition.includes(this.formData[field.if.model])) {
    //         return true
    //       }
    //       break
    //     case "not":
    //       if (field.if.condition != this.formData[field.if.model]) {
    //         return true
    //       }
    //       break
    //     case "notIn":
    //       if (!field.if.condition.includes(this.formData[field.if.model])) {
    //         return true
    //       }
    //       break
    //     default:
    //       return false
    //   }
    //   return false
    // },
  },
}
</script>
<style>
.inputs {
  justify-content: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}

/* Animações de entrada e saída podem utilizar diferentes  */
/* funções de duração e de tempo.                          */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active em versões anteriores a 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
