<template>
  <div>
    <!-- <form-progress /> -->
    <div class="nav">
      <type-based-transition
        v-if="formState.activeField > 0 && !formState.isComplete"
        transition-type="fadeUpDown"
      >
        <div
          class="form-button back"
          @click="$emit('back')"
        >
          <v-icon
            dark
            left
          >
            mdi-arrow-left
          </v-icon>Voltar
        </div>
      </type-based-transition>
      <type-based-transition
        v-if="!formState.isComplete"
        transition-type="fadeUpDown"
      >
        <!-- <button class="form-button next" v-if="formState.isValid" @click="$emit('next')">Próximo</button> -->
        <button
          class="form-button next"
          @click="$emit('next')"
        >
          Próximo<v-icon dark>
            mdi-arrow-right
          </v-icon>
        </button>
      </type-based-transition>
      <type-based-transition
        v-else
        transition-type="fadeUpDown"
      >
        <div class="col-12">
          <div
            class="row"
            style="justify-content: center; white-space: normal;"
          >
            <h4>
              Quer receber a resposta da simulação mais rápido?
              <v-checkbox
                v-model="optwhatsapp"
                label="Sim, quero receber comunicação por Whatsapp"
                color="success"
              />
            </h4>
          </div> 
          <div
            class="col-12 col-md-6 col-lg-4"
            style="white-space: initial; margin: auto;"
          >
            <div
              class="row"
              style="justify-content: center"
            >
              <p class="subtitle-2">
                Ao continuar você autoriza a Itscred e seus parceiros a consultar o seu histórico de crédito e de eventuais pessoas jurídicas das quais você seja sócio(a), no Sistema de Informações de Crédito – SCR do Banco Central do Brasil.
              </p>
              <template>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Saiba mais
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <span class="subtitle-2"> Para te oferecer a melhor proposta de crédito, solicitamos a autorização para consultar o histórico de movimentações financeiras atreladas ao seu CPF e de eventuais pessoas jurídicas das quais você seja sócio(a) no Sistema de Informações de Crédito do Banco Central - SRC. Consigo continuar sem autorizar esta consulta? Não. Você precisa autorizar a consulta para podermos dar continuidade a sua solicitação Ainda tem dúvidas sobre o que é o SCR? <a href="https://www.bcb.gov.br/acessoinformacao/perguntasfrequentes-respostas/faq_scr"> Veja o FAQ do Banco Central.</a></span>
                      <br> <b>Parceiros: Creditas, BV, Cashme, Pontte, Bari, Biz Capital, Nexxos</b>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </div>
          </div> 
          <div
            class="row"
            style="justify-content: center; margin-top: 10px; margin-bottom: 10px;"
          >
            <button
              class="form-button next"
              @click="$emit('submit')"
            >
              Simular<v-icon dark>
                mdi-send
              </v-icon>
            </button><br>
          </div>
        </div>
      </type-based-transition>
    </div>
  </div>
</template>
<script>
import TypeBasedTransition from "../Transitions/TypeBasedTransition.vue"
import { mapState } from "vuex"
export default {
  name: "FormNav",
  components: {
    TypeBasedTransition,
  },
  inject: ["formState"],
  data() {
    return {
    optwhatsapp: true
    }
  },
  // computed:{
  //   ...mapState({
  //     optwhatsapp: state => state.lead.formData.optwhatsapp
  //   }),
  // },
  watch:{
    //     optwhatsapp(newValue) {
    //   newValue ? (console.log('d')) : (console.log('a'))
    // },
    optwhatsapp: function(val){
       this.$store.commit("lead/updateField", {
      key: "optwhatsapp",
      value: val,
    })
    }
  },
}
</script>
