<template>
  <div class="form-error-message">
    <type-based-transition transition-type="fadeUpDown">
      <span> <slot /> </span>
    </type-based-transition>
  </div>
</template>
<script>
import TypeBasedTransition from "../Transitions/TypeBasedTransition.vue"

export default {
  name: "FieldError",
  components: {
    TypeBasedTransition
  },
  inject: ["formState"]
}
</script>
