<template>
  <div class="container">
    <div
      v-for="(field, key) in options"
      :key="key"
    >
      {{ field.label }}
      <input
        v-model="formData[field.name]"
        v-validate="field.validation" 
        :type="field.type"
        :name="field.name"
        v-bind="{...field.options.attrs}"
        @input="$emit('input', $event.target.value)"
      >
    </div>
    <span>{{ errors.first(field.name) }}</span>
  </div>
</template>
<script>

export default {
  props: ['options'],
}
</script>
<style scoped>
.container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
}
.break {
  flex-basis: 100%;
  height: 0;
}
</style>